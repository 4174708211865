var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lesson"},[(!_vm.loading.lesson)?_c('div',{staticClass:"lesson__content"},[(_vm.currentLesson?.is_liberated === true)?[_c('div',{staticClass:"lesson__topbar"},[_c('div',{staticClass:"flex-1"}),_c('span',{staticClass:"lesson__topbar--rating"},[_vm._v(" "+_vm._s(_vm.$t("my_courses.avaliar"))+" ")]),_c('StarRating',{attrs:{"show-rating":false,"star-size":20,"rating":_vm.currentLesson.my_rating || 0},on:{"rating-selected":_vm.postRating}})],1),_c('div',{staticClass:"lesson__header"},[_c('div',{staticClass:"lesson__header__info"},[_c('div',{staticClass:"lesson__header__grid__title"},[_c('span',{staticClass:"lesson__header__title"},[_vm._v(_vm._s(_vm.currentLesson.title))])]),_c('div',{class:{
            lesson__header__controls: true,
            'lesson__header__controls--mobile': _vm.getLayout.breakpoint == 1,
          }},[_c('ButtonPlay',{staticClass:"lesson__header__controls__prev",attrs:{"size":"sm","disabled":!_vm.prevLesson},on:{"click":function($event){return _vm.gotoPrevLesson()}}},[_c('IconSax',{attrs:{"primary":!!_vm.prevLesson,"name":"arrow-left"}}),_vm._v(" "+_vm._s(_vm.$t("my_courses.prev"))+" ")],1),_c('ButtonPlay',{staticClass:"lesson__header__controls__complete",attrs:{"size":"sm","disabled":_vm.currentLesson.is_completed},on:{"click":_vm.completeLesson}},[_vm._v(" "+_vm._s(_vm.$t("my_courses.marcar"))+" "),_c('IconSax',{attrs:{"name":"tick-circle"}})],1),_c('ButtonPlay',{staticClass:"lesson__header__controls__next",attrs:{"size":"sm","disabled":!_vm.nextLesson},on:{"click":function($event){return _vm.gotoNextLesson()}}},[_vm._v(" "+_vm._s(_vm.$t("my_courses.next"))+" "),_c('IconSax',{attrs:{"primary":!!_vm.nextLesson,"name":"arrow-right-1"}})],1)],1)]),(_vm.hasPermission && _vm.studentCantView)?_c('div',{staticClass:"lesson__edit"},[_c('router-link',{attrs:{"to":'/config_curso/' +
            _vm.currentLesson.course_id +
            '/module/' +
            _vm.currentLesson.module_id +
            '/lesson?lesson_id=' +
            _vm.currentLesson.id}},[_c('span',{staticClass:"lesson__edit--name"},[_vm._v(" "+_vm._s(_vm.$t("my_courses.editar"))+" ")]),_c('span',{staticClass:"lesson__edit--icon"},[_c('IconSax',{attrs:{"name":"edit","primary":""}})],1)])],1):_vm._e()])]:_vm._e(),(_vm.currentLesson?.is_liberated === true)?[(_vm.currentLesson.mediaType === 'youtube')?_c('LessonPlayYoutube',{staticClass:"lesson__player",attrs:{"porcentagem":_vm.lessonPercentageCompletion,"type":_vm.currentLesson.mediaType,"source":_vm.currentLesson.source,"thumb":_vm.currentLesson.thumb}}):_vm._e(),(_vm.currentLesson.mediaType === 'voomptube')?_c('VoompTube',{attrs:{"type":_vm.currentLesson.mediaType,"data":_vm.currentLesson.source,"next":_vm.gotoNextLesson}}):_vm._e(),(_vm.currentLesson.mediaType === 'vimeo')?_c('LessonPlayVimeo',{staticClass:"textexx lesson__player",attrs:{"porcentagem":_vm.lessonPercentageCompletion,"source":_vm.currentLesson.source}}):_vm._e(),(_vm.currentLesson.mediaType === 'iframe')?_c('VideoPlayerIframe',{staticClass:"lesson__player",attrs:{"porcentagem":_vm.lessonPercentageCompletion,"source":_vm.currentLesson.source}}):_vm._e(),(
        _vm.currentLesson.mediaType === 'panda' &&
        _vm.currentLesson.can_be_displayed
      )?_c('LessonPlayPanda',{staticClass:"lesson__player",attrs:{"porcentagem":_vm.lessonPercentageCompletion,"source":_vm.currentLesson.source}}):_vm._e(),(_vm.currentLesson && _vm.currentLesson.mediaType === 'audio')?_c('LessonPlayAudio',{staticClass:"lesson__player",attrs:{"porcentagem":_vm.lessonPercentageCompletion,"type":_vm.currentLesson.mediaType,"source":_vm.currentLesson}}):_vm._e(),(_vm.currentLesson.mediaType === 'quiz')?_c('LessonQuiz',{attrs:{"lesson":_vm.currentLesson,"canComplete":!_vm.currentLesson.is_completed},on:{"complete":function($event){return _vm.completeLesson()}}}):_vm._e(),(_vm.currentLesson.mediaType === 'exercise')?_c('LessonExercise',{attrs:{"getSelectedExercise":_vm.currentLesson.exercises[0]?.question,"nextButton":false}}):_vm._e()]:_vm._e(),(_vm.currentLesson?.is_liberated === true)?[(_vm.showLessonTab)?_c('div',{staticClass:"lesson__tab"},[_c('a',{class:{
          lesson__tab__item: true,
          'lesson__tab__item--selected': _vm.tabSelected == 'description',
        },on:{"click":function($event){_vm.tabSelected = 'description'}}},[_c('span',{staticClass:"lesson__tab__item__icon"},[_c('IconSax',{attrs:{"size":"1rem","name":"document-text","primary":""}})],1),_c('span',{staticClass:"lesson__tab__item__label"},[_vm._v(" "+_vm._s(_vm.$t("my_courses.description")))])]),_c('a',{class:{
          lesson__tab__item: true,
          'lesson__tab__item--selected': _vm.tabSelected == 'attachements',
        },on:{"click":function($event){_vm.tabSelected = 'attachements'}}},[_c('span',{staticClass:"lesson__tab__item__icon"},[_c('IconSax',{attrs:{"size":"1rem","name":"folder","primary":""}})],1),_c('span',{staticClass:"lesson__tab__item__label"},[_vm._v(_vm._s(_vm.$t("my_courses.documents"))+" ("+_vm._s(_vm.attachmentsQuantity)+")")])]),(_vm.settings['coment_lesson'])?_c('a',{class:{
          lesson__tab__item: true,
          'lesson__tab__item--selected': _vm.tabSelected == 'comments',
        },on:{"click":function($event){_vm.tabSelected = 'comments'}}},[_c('span',{staticClass:"lesson__tab__item__icon"},[_c('IconSax',{attrs:{"size":"1rem","name":"messages-2","primary":""}})],1),_c('span',{staticClass:"lesson__tab__item__label"},[_vm._v(_vm._s(_vm.$t("my_courses.comments"))+" ("+_vm._s(_vm.commentsQuantity)+")")])]):_vm._e()]):_vm._e(),(_vm.tabSelected == 'description')?_c('div',{staticClass:"lesson__content__description"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.currentLesson.content)}})]):_vm._e(),(_vm.tabSelected == 'attachements')?_c('div',{staticClass:"lesson__content__attachements"},[_c('LessonAttachments',{attrs:{"lesson":_vm.currentLesson}})],1):_vm._e(),(_vm.tabSelected == 'comments')?_c('LessonCommentList'):_vm._e()]:_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }